<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-card-title>Пользователи</v-card-title>
      <v-container>
        <v-data-table
          :headers="headers"
          :items="filteredUsers"
          :items-per-page="50"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              color="white"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
              <v-spacer/>
              <router-link :to="{ name: 'UserCreate' }">
                <v-btn
                  color="primary"
                  dark
                >
                  Добавить
                </v-btn>
              </router-link>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <router-link :to="{ name: 'UserView', params: { id: item.id } }">
                  {{ item.id }}
                </router-link>
              <td>
                <router-link :to="{ name: 'UserView', params: { id: item.id } }">
                  {{ item.first_name + ' ' + item.last_name }}
                </router-link>
              </td>
              <td>{{ item.phone }}</td>
              <td>{{ item.username }}</td>
              <td>
              <router-link :to="{ name: 'CompanyView', params: { id: item.company } }">
                {{ item.company_display }}
              </router-link>
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.group_name }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-main>
</template>


<script>
import account from "@/api/account";

export default {
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'ФИО', value: 'first_name'},
        {text: 'Номер телефона', value: 'phone'},
        {text: 'Никнейм', value: 'username'},
        {text: 'Компания', value: 'company_display'},
        {text: 'Почта', value: 'email'},
        {text: 'Роль', value: 'group_name'},
      ],
      users: [],
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user => {
        const searchTerm = this.search.toLowerCase();
        return (
          user.username.toLowerCase().includes(searchTerm) ||
          user.first_name.toLowerCase().includes(searchTerm) ||
          user.last_name.toLowerCase().includes(searchTerm) ||
          user.phone.toLowerCase().includes(searchTerm)
        );
      });
    },
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      this.loading = true;
      try {
        const response = await account.getUserList();
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
